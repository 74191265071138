<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <div class="col text-right" v-if="showRegister">
        <q-btn
          no-caps
          class="col q-ml-sm"
          padding="xs md"
          color="primary"
          :label="getRes('System.Button.Register')"
          rounded
          :to="registerTenderBriefingFormRoute()"
          style="width: 120px"
        />
      </div>

      <pro-deck
        :title="getRes('Form.Field.TenderBriefingAttachment')"
        :cards="tenderBriefingAttachment"
        v-slot="{ card }"
        v-if="
          tenderBriefingAttachment.length &&
          tenderBriefingAttachment[0].files &&
          tenderBriefingAttachment[0].files.length
        "
        class="q-mb-md"
      >
        <pro-deck-card v-bind="card" expandable :title="card.title">
          <q-list v-if="card.files && card.files.length">
            <q-item
              class="grey-on-hover"
              v-for="file in card.files"
              :key="file.fileId"
            >
              <pro-file :file="file" show-download-button />
            </q-item>
          </q-list>
        </pro-deck-card>
      </pro-deck>

      <pro-deck
        :title="getRes('Form.Field.RegistrationOfTenderBriefing')"
        :cards="cards"
        v-slot="{ card }"
      >
        <pro-deck-card :title="card.title" :actions="card.actions" expandable>
          <template>
            <div class="col-auto text-bold q-mr-md" style="font-size: 17px">
              {{ getRes("Form.Field.AttendeeList") }}:
            </div>
            <div
              class="row no-wrap"
              v-for="(row, index) in card.attendeeList"
              :key="index"
            >
              <span class="col-auto text-bold q-mr-md" style="font-size: 17px">
                {{ getRes("Form.Field.Attendee") }} {{ index + 1 }}:
                <br />
              </span>
              <div class="col row">
                <span class="col-4" style="font-size: 17px">
                  {{ getRes("Form.Field.AttendeeName") }}:
                </span>
                <span class="col-8" style="font-size: 17px">
                  {{ row.attendeeName }}<br />
                </span>
                <span class="col-4" style="font-size: 17px"
                  >{{ getRes("Form.Field.PositionTitle") }}:</span
                >
                <span class="col-8" style="font-size: 17px">
                  {{ row.position }}<br />
                </span>
                <span class="col-4" style="font-size: 17px"
                  >{{ getRes("Form.Field.EmailAddress") }}:</span
                >
                <span class="col-8" style="font-size: 17px">
                  {{ row.email }}<br />
                </span>
                <span class="col-4" style="font-size: 17px"
                  >{{ getRes("Form.Field.ContactNo") }}:</span
                >
                <span class="col-8" style="font-size: 17px">
                  {{ row.contactNumber }}<br />
                </span>
                <span
                  class="col-4"
                  style="font-size: 17px"
                  v-if="row.personalId !== null"
                >
                  {{ getRes("Form.Field.HKIDPassportNo") }}:
                </span>
                <span
                  class="col-8"
                  style="font-size: 17px"
                  v-if="row.personalId !== null"
                >
                  {{ row.personalId }}<br />
                </span>
              </div>
            </div>
            <pro-folders
              class="q-ma-md"
              :folders="card.fileList"
              show-download-button
            />
          </template>
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProFolders from "../../../../components/PROSmart/ProFolders";
import ProFile from "../../../../components/PROSmart/ProFile";

const viewFormRouteName = "DocumentViewForm";

export default {
  components: { ProFile, ProMenuLayout, ProDeck, ProDeckCard, ProFolders },
  data() {
    return {
      cards: [],
      tenderBriefingInfo: {},
      viewFormCode: "",
      editWorkflowProcessCode: "",
      tenderBriefingAttachment: [],
      showRegister: false,
    };
  },
  methods: {
    formatDate(date) {
      return this.$proSmart.common.getFormattedDate(new Date(date + "+0800"));
    },
    registerTenderBriefingFormRoute() {
      return {
        name: viewFormRouteName,
        params: {
          mode: "Edit",
          code: this.editWorkflowProcessCode,
        },
      };
    },
  },
  async created() {
    this.$proSmart.documentUi
      .getTenderBriefingDate(this, parseInt(this.$route.params.id))
      .then((b) => {
        this.showRegister = b.success;
      });

    this.tenderBriefingAttachment = await this.$proSmart.documentUi.getTenderBriefingRegisterForm(
      this,
      parseInt(this.$route.params.id)
    );
    const getArr = (files) => {
      return files.map((object) => ({
        folderDescription: "",
        ...object,
      }));
    };

    this.tenderBriefingInfo = await this.$proSmart.documentUi
      .getTenderBriefingByTenderer(this, this.$route.params.id)
      .then((info) => {
        return info;
      });

    if (this.tenderBriefingInfo.submitType === "Attachment") {
      if (this.tenderBriefingInfo.includedPersonalData) {
        this.viewFormCode = "Supplier_Tender_RegForTendBrAttRestr";
        this.editWorkflowProcessCode = "S_T_RegForTendBrAttR";
      } else {
        this.viewFormCode = "Supplier_Tender_RegForTendBrAtt";
        this.editWorkflowProcessCode = "S_T_RegForTendBrAtt";
      }
    } else if (this.tenderBriefingInfo.submitType === "WebFormRestricted") {
      this.viewFormCode = "Supplier_Tender_RegForTendBrRestrPers";
      this.editWorkflowProcessCode = "S_T_TendBrRestrPers";
    } else if (this.tenderBriefingInfo.submitType === "WebFormNonRestricted") {
      this.viewFormCode = "Supplier_Tender_RegForTendBrNonRestr";
      this.editWorkflowProcessCode = "S_T_TendBrNonRestr";
    }
    await this.$proSmart.documentUi
      .getTenderBriefingRegistrationByTenderer(this, this.$route.params.id)
      .then((info) => {
        info.splice(1);
        this.cards = info.map((submission) => ({
          key: submission.id,
          title: this.$proSmart.common.format.dateTime(
            new Date(submission.submissionDate)
          ),
          tendererId: submission.tendererId,
          attendeeList: submission.attendeeList,
          submissionDate: submission.submissionDate,
          fileList: getArr(submission.tendererFile.fileList),
        }));
      });
  },
};
</script>
