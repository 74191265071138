var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pro-menu-layout',[_c('div',{staticClass:"q-pa-md"},[(_vm.showRegister)?_c('div',{staticClass:"col text-right"},[_c('q-btn',{staticClass:"col q-ml-sm",staticStyle:{"width":"120px"},attrs:{"no-caps":"","padding":"xs md","color":"primary","label":_vm.getRes('System.Button.Register'),"rounded":"","to":_vm.registerTenderBriefingFormRoute()}})],1):_vm._e(),(
        _vm.tenderBriefingAttachment.length &&
        _vm.tenderBriefingAttachment[0].files &&
        _vm.tenderBriefingAttachment[0].files.length
      )?_c('pro-deck',{staticClass:"q-mb-md",attrs:{"title":_vm.getRes('Form.Field.TenderBriefingAttachment'),"cards":_vm.tenderBriefingAttachment},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var card = ref.card;
return [_c('pro-deck-card',_vm._b({attrs:{"expandable":"","title":card.title}},'pro-deck-card',card,false),[(card.files && card.files.length)?_c('q-list',_vm._l((card.files),function(file){return _c('q-item',{key:file.fileId,staticClass:"grey-on-hover"},[_c('pro-file',{attrs:{"file":file,"show-download-button":""}})],1)}),1):_vm._e()],1)]}}],null,false,1004662576)}):_vm._e(),_c('pro-deck',{attrs:{"title":_vm.getRes('Form.Field.RegistrationOfTenderBriefing'),"cards":_vm.cards},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var card = ref.card;
return [_c('pro-deck-card',{attrs:{"title":card.title,"actions":card.actions,"expandable":""}},[[_c('div',{staticClass:"col-auto text-bold q-mr-md",staticStyle:{"font-size":"17px"}},[_vm._v(" "+_vm._s(_vm.getRes("Form.Field.AttendeeList"))+": ")]),_vm._l((card.attendeeList),function(row,index){return _c('div',{key:index,staticClass:"row no-wrap"},[_c('span',{staticClass:"col-auto text-bold q-mr-md",staticStyle:{"font-size":"17px"}},[_vm._v(" "+_vm._s(_vm.getRes("Form.Field.Attendee"))+" "+_vm._s(index + 1)+": "),_c('br')]),_c('div',{staticClass:"col row"},[_c('span',{staticClass:"col-4",staticStyle:{"font-size":"17px"}},[_vm._v(" "+_vm._s(_vm.getRes("Form.Field.AttendeeName"))+": ")]),_c('span',{staticClass:"col-8",staticStyle:{"font-size":"17px"}},[_vm._v(" "+_vm._s(row.attendeeName)),_c('br')]),_c('span',{staticClass:"col-4",staticStyle:{"font-size":"17px"}},[_vm._v(_vm._s(_vm.getRes("Form.Field.PositionTitle"))+":")]),_c('span',{staticClass:"col-8",staticStyle:{"font-size":"17px"}},[_vm._v(" "+_vm._s(row.position)),_c('br')]),_c('span',{staticClass:"col-4",staticStyle:{"font-size":"17px"}},[_vm._v(_vm._s(_vm.getRes("Form.Field.EmailAddress"))+":")]),_c('span',{staticClass:"col-8",staticStyle:{"font-size":"17px"}},[_vm._v(" "+_vm._s(row.email)),_c('br')]),_c('span',{staticClass:"col-4",staticStyle:{"font-size":"17px"}},[_vm._v(_vm._s(_vm.getRes("Form.Field.ContactNo"))+":")]),_c('span',{staticClass:"col-8",staticStyle:{"font-size":"17px"}},[_vm._v(" "+_vm._s(row.contactNumber)),_c('br')]),(row.personalId !== null)?_c('span',{staticClass:"col-4",staticStyle:{"font-size":"17px"}},[_vm._v(" "+_vm._s(_vm.getRes("Form.Field.HKIDPassportNo"))+": ")]):_vm._e(),(row.personalId !== null)?_c('span',{staticClass:"col-8",staticStyle:{"font-size":"17px"}},[_vm._v(" "+_vm._s(row.personalId)),_c('br')]):_vm._e()])])}),_c('pro-folders',{staticClass:"q-ma-md",attrs:{"folders":card.fileList,"show-download-button":""}})]],2)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }